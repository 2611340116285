.controls {
  width: 45vw;
  overflow: hidden;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
}
.accordion-container {
  margin-top: auto;
}
.reset-btn {
  border: 1px solid rgb(180, 180, 180);
  background: #e7e7e7;
  font-size: 10px;
  opacity: 0.5;
  padding: 4px 10px;
  border-radius: 5px;
  cursor: pointer;
}
