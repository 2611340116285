.color-swatch-button {
  width: 25px;
  height: 25px;
  background-color: aqua;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  border: 2px solid #ced4da;
  transition: all 0.25s ease-in-out;
}
.layer-color-options {
  display: flex;
  flex-wrap: wrap;
}

.layer-color-options > .color-swatch-button {
  margin: 5px;
}

.layer-color-options > .color-swatch-button:hover {
  border: 2px solid #40c057;
  transform: scale(0.85);
}

.layer-color-custom-container {
  display: flex;
  align-items: center;
}
