.shadow-option {
  display: flex;
  cursor: pointer;
  border: 2px solid;
  border-color: transparent;
  transition: all 0.25s ease-in-out;
}
.shadow-option:hover {
  border-color: rgb(0, 153, 255);
  background-color: rgba(0, 153, 255, 0.03) !important;
}
.shadow-option:active {
  border-color: rgb(255, 238, 0) !important;
  transition: none !important;
}

.shadow-option.is-active {
  border-color: #50cd65 !important;
  transform: scale(0.98);
}
.shadow-option-container {
  border: 0.5px solid #f8f8f8;
  overflow: hidden;
}
.shadow-option-active {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
