.App {
  display: flex;
  height: calc(100vh - 50px);
  justify-content: space-between;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
}
.viewport {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
