.select-preview-modifiers-btn {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.select-preview-modifiers-btn svg {
  transition: all 0.25s ease-in-out;
}
.select-preview-modifiers-btn:hover svg:not(.is-modified) {
  color: rgb(94, 94, 94);
}

.modifier-row {
  padding-bottom: 8px;
  border-bottom: 1px dashed #ededed;
  margin-top: 8px;
}
.modifier-row:last-of-type {
  border-bottom: 0 !important;
  padding-bottom: 0;
}

.modifier-row-text {
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}
