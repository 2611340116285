.control-panel-contents {
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.control-panel-slider:hover {
  opacity: 1 !important;
}

.control-panel-slider {
  transition: all 0.25s ease-in-out;
}
