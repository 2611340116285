.shadow-list {
  height: calc(70vh - 100px);
  width: 55vw;
  background: linear-gradient(90deg, white, transparent);
  max-height: 100%;
  overflow-y: scroll;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  margin-top: -1px;
  border-bottom: 1px solid #e6e6e6;
}
