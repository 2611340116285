.layer-inspector-composite-container {
  height: 30vh;
  background-color: white;
  z-index: 10;
}

.layer-inspector-composite-container .mantine-Tabs-root.disable-tabs {
  opacity: 0.5;
  pointer-events: none;
}
