.shadow-layer-highlight.null-state:hover {
  border: 2px dashed rgb(205 205 205 / 50%);
  cursor: auto;
}
.shadow-layer-highlight.null-state .shadow-layer-render {
  background-color: initial !important;
  background: initial !important;
  border: 1px solid rgb(205 205 205 / 50%);
  position: relative;
}

.shadow-layer-render.null-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.null-icon {
  padding-bottom: 20px;
}
.null-title,
.null-icon {
  opacity: 0.25;
  user-select: none;
  -webkit-user-select: none;
}
