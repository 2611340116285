.layer-inspector-input {
  display: flex;
  outline: none;
  background: transparent;
  color: gray;
  border: 2px solid #e6e6e6;
  width: 50px;
  margin-right: 10px;
  border-radius: 5px;
  padding: 5px;
}

.layer-inspector-inputs-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.layer-inspector-input-label-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  border-right: 1px solid #e6e6e6;
  margin-left: 10px;
}
.layer-inspector-input-label-container .label {
  opacity: 0.5;
  text-transform: capitalize;
  font-size: 12px;
}

.layer-preview-color {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}
.layer-preview-color svg {
  opacity: 0.1;
  font-size: 18px;
  transform: none;
}
.layer-preview-color:hover svg {
  opacity: 1;
}
.layer-preview-icon-container {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.layer-preview-id-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.layer-preview-id-text {
  font-size: 12px;
  opacity: 0.75;
  user-select: none;
}

.layer-inpector-root {
  border-bottom: 1px solid #e6e6e6;
  background: white;
  padding-bottom: 5px;
  padding-top: 5px;
}

.layer-inpector-data-root {
  display: flex;
  gap: 5px;
  padding: 5px;
}

.shadow-layer-inspector-container {
  display: flex;
  flex-direction: column;
  max-height: 40vh;
  /* flex-wrap: wrap; */
  overflow-y: scroll;
}

.layer-copy-button-container {
  display: flex;
  align-items: center;
}

.layer-inspector-composite-container {
  background-color: white;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 2px solid #e6e6e6;
}

.layer-inspector-composite-container .layer-code {
  border: 1px solid #40c057;
}
