.toolbar {
  display: flex;
  width: 100%;
  height: 50px;
  background: linear-gradient(90deg, white, #f8fdfd);
  border-bottom: 1px solid #f7f7f7;
  z-index: 5;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.shadow-list-options {
  width: 55vw;
  height: 100%;
  display: flex;
  border-right: 2px solid #f7f7f7;
}
.shadow-render-options {
  width: 45vw;
  height: 100%;
  display: flex;
  align-items: center;
}

.select-container {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 0;
  box-sizing: border-box;
}

.select-outer-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: fit-content;
}

span.select-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 5px;
}
