.shadow-layer-viewer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  flex-direction: column;
  position: relative;
  background-color: white;
  border-bottom: 1px solid #f7f7f7;
  overflow: hidden;
  background-image: linear-gradient(45deg, #f0f0f0 25%, transparent 25%),
    linear-gradient(-45deg, #f0f0f0 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #f0f0f0 75%),
    linear-gradient(-45deg, transparent 75%, #f0f0f0 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.shadow-layer-container {
  display: flex;
  width: 30vw;
  max-width: 375px;
  height: 40vh;
  max-height: 375px;
  position: absolute;
}
.shadow-layer-render {
  height: 80%;
  width: 80%;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.shadow-layer-highlight {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 2px dashed rgb(205 205 205 / 50%);
  transition: opacity 0.25s ease-in-out;
}
.shadow-layer-highlight:hover {
  border-color: cyan;
  cursor: pointer;
}
.shadow-viewer-render-window {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shadow-layer-title {
  position: absolute;
  top: 0;
  left: 0;
  font-family: sans-serif;
  background: rgb(16 189 255 / 5%);
  padding: 5px;
  opacity: 0;
  user-select: none;
  pointer-events: none;
}
.shadow-layer-highlight:hover .shadow-layer-title {
  opacity: 1;
}

.shadow-layer-container:hover ~ .shadow-layer-container {
  opacity: 0.15;
}
.shadow-layer-container:hover ~ .shadow-layer-container .shadow-layer-render {
  box-shadow: none !important;
}

.shadow-layer-highlight:hover {
  border-color: cyan;
  cursor: pointer;
  backdrop-filter: blur(50px);
  background: rgb(255 255 255 / 74%);
}

.shadow-layer-highlight:hover .shadow-layer-render {
  background: rgb(255 255 255 / 70%);
}
