html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
}
body {
  background: radial-gradient(
      circle at 15% 50%,
      rgb(237, 233, 254),
      rgba(255, 255, 255, 0) 25%
    ),
    radial-gradient(
      circle at 85% 30%,
      rgb(216, 243, 246),
      rgba(255, 255, 255, 0) 25%
    );
}

.App *::-webkit-scrollbar {
  width: 16px;
}
.App *::-webkit-scrollbar-track {
  background: #fcfcfc;
  border: none;
}
.App *::-webkit-scrollbar-track:hover {
  background: #f7f7f7;
  border: none;
}
.App *::-webkit-scrollbar-thumb {
  background: #dadce0;
  background-clip: padding-box;
  border: 4px solid transparent;
  border-radius: 8px;
  box-shadow: none;
  min-height: 50px;
}
